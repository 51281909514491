import { FormattedMessage } from 'gatsby-plugin-intl';
import {
  calculateMaterialWall,
  MaterialCalculatorStep1,
  MaterialCalculatorStep2,
  MaterialWallResult,
  MaterialWallStep1,
  MaterialWallStep2,
  useMaterialCalculatorHook,
  WallMaterialCalculatorResult,
} from 'modules/calculator';
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { calculatorStyles } from 'styles';

type MaterialSteps = 'step1' | 'step2' | 'result';

const MaterialCaclulator: React.FC = () => {
  const [calcResult, setCalcResult] = useState<MaterialWallResult | null>(null);
  const [step1Inputs, setStep1Inputs] =
    useState<MaterialWallStep1 | null>(null);
  const [step2Inputs, setStep2Inputs] =
    useState<MaterialWallStep2 | null>(null);
  const [showMaterialPage, setShowMaterialPage] =
    useState<MaterialSteps>('step1');

  const {
    numberOfLoadWallDimensions,
    numberOfLoadWallHoles,
    numberOfDividingWallDimensions,
    numberOfDividingWallHoles,
    addNewWallDimension,
    addNewWallHole,
    handleRemoveWallDimensionInput,
    handleRemoveWallHoleInput,
    resetAll,
  } = useMaterialCalculatorHook();

  return (
    <div css={calculatorStyles.calculatorConatiner}>
      <h2 css={calculatorStyles.title}>
        <FormattedMessage id="calculator.material.title" />
      </h2>
      {showMaterialPage === 'step1' ? (
        <MaterialCalculatorStep1
          data={step1Inputs}
          numberOfLoadWallDimensions={numberOfLoadWallDimensions}
          numberOfLoadWallHoles={numberOfLoadWallHoles}
          handleRemoveWallDimensionInput={handleRemoveWallDimensionInput}
          addNewWallDimension={addNewWallDimension}
          handleRemoveWallHoleInput={handleRemoveWallHoleInput}
          addNewWallHole={addNewWallHole}
          onCancelClick={onCancelClick}
          onSubmit={onSubmitStep1}
        />
      ) : showMaterialPage === 'step2' ? (
        <MaterialCalculatorStep2
          data={step2Inputs}
          numberOfDividingWallHoles={numberOfDividingWallHoles}
          handleRemoveWallDimensionInput={handleRemoveWallDimensionInput}
          addNewWallDimension={addNewWallDimension}
          numberOfDividingWallDimensions={numberOfDividingWallDimensions}
          handleRemoveWallHoleInput={handleRemoveWallHoleInput}
          addNewWallHole={addNewWallHole}
          onSkipClick={onSkipStep2Click}
          onSubmit={onSubmitStep2}
        />
      ) : (
        calcResult && <WallMaterialCalculatorResult data={calcResult} />
      )}
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        rtl={false}
        pauseOnFocusLoss
        closeButton={false}
        style={{
          width: '70%',
        }}
        toastStyle={{
          borderRadius: '4px',
        }}
      />
    </div>
  );

  function onSubmitStep1(data: MaterialWallStep1) {
    setStep1Inputs(data);
    setShowMaterialPage('step2');
  }

  function onSubmitStep2(data: MaterialWallStep2) {
    setStep2Inputs(data);
    if (step1Inputs)
      setCalcResult(
        calculateMaterialWall({
          step1Data: step1Inputs,
          step2Data: data,
        }),
      );
    setShowMaterialPage('result');
  }

  function onCancelClick() {
    setCalcResult(null);
    setStep1Inputs(null);
    setStep2Inputs(null);
    resetAll();
    setShowMaterialPage('step1');
  }

  function onSkipStep2Click() {
    if (step1Inputs)
      setCalcResult(calculateMaterialWall({ step1Data: step1Inputs }));
    setShowMaterialPage('result');
  }
};

export default MaterialCaclulator;
